import { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { AppContext } from '../components/FaqOoi/Widget/SettingsProvider/SettingsProvider';
import { FAQService } from '../utils/FAQService';
import { usePaginationQuestions } from './usePaginationQuestions';
import { usePaginationSearchQuestions } from './usePaginationSearchQuestions';

export const useQuestions = (initialQuestions: QuestionEntry[]) => {
  const { instance, instanceId } = useContext(AppContext);
  const { multilingual } = useEnvironment();

  const api = useMemo(
    () => new FAQService(instance ?? '', instanceId, multilingual),
    [instance, instanceId, multilingual],
  );

  const [questionsList, setQuestionsList] = useState<QuestionEntry[]>(
    initialQuestions || [],
  );

  const bottomRef = useRef<HTMLDivElement | null>(null);

  // Manage load more logic
  usePaginationQuestions({
    api,
    setQuestionsList,
    bottomRef,
  });

  // Manage load more logic when searching
  usePaginationSearchQuestions({
    api,
    setQuestionsList,
    bottomRef,
  });

  useEffect(() => {
    setQuestionsList((prev) =>
      JSON.stringify(prev) !== JSON.stringify(initialQuestions)
        ? initialQuestions
        : prev,
    );
  }, [initialQuestions]);

  return { questionsList, bottomRef };
};
