import React from 'react';
import NumberedAccordions from '../Number/NumberedAccordions';
import AccordionWrapper from '../Accordion/AccordionWrapper';
import settingsParams, { LayoutType } from '../../../settingsParams';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import ListWrapper from '../List/ListsWrapper';

const SelectedLayout = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const settings: ISettingsContextValue = useSettings();
  const layout: LayoutType = settings.get(settingsParams.layout);
  const numberLayout = layout === LayoutType.Stripes;
  const accordionLayout = layout === LayoutType.Collapse;
  const listLayout = layout === LayoutType.Basic;

  return (
    <>
      {numberLayout && (
        <NumberedAccordions filteredQuestions={filteredQuestions} />
      )}
      {listLayout && <ListWrapper filteredQuestions={filteredQuestions} />}
      {accordionLayout && (
        <AccordionWrapper filteredQuestions={filteredQuestions} />
      )}
    </>
  );
};

export default SelectedLayout;
