import React, { useContext } from 'react';
import { Text, useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../../Widget.st.css';
import { AppContext } from '../../SettingsProvider/SettingsProvider';
import { DATA_HOOKS } from '../../../../../constants/dataHooksElements';

const NoQuestionsResult = () => {
  const { searchTerm } = useContext(AppContext);
  const noQuestionResultSearch = searchTerm !== '';
  const { t } = useTranslation();
  const { mobile } = useTPAComponentsContext();

  return (
    <div
      className={st(classes.searchMessage, {
        noResult: true,
        mobile,
        emptyTab: !noQuestionResultSearch,
      })}
      data-hook={DATA_HOOKS.NO_QUESTION_RESULTS}
    >
      <Text
        className={st(classes.searchResultText)}
        data-hook={DATA_HOOKS.WIDGET_NO_SEARCH_RESULTS}
      >
        <Trans
          tOptions={{ interpolation: { escapeValue: false } }}
          i18nKey={
            noQuestionResultSearch
              ? 'widget.search.tab.no.search.results.found'
              : 'widget.search.tab.no.results.found'
          }
          values={{
            tryAgain: noQuestionResultSearch
              ? t('widget.search.tab.no.search.results.found.try.again')
              : t('widget.search.tab.no.results.found.try.again'),
          }}
        >
          <div></div>
        </Trans>
      </Text>
    </div>
  );
};

export default NoQuestionsResult;
