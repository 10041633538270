import React from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import NoQuestionsResult from './NoQuestionsResult/NoQuestionsResult';
import QuestionsResultsFound from './QuestionsResultsFound/QuestionsResultsFound';
import { useQuestions } from '../../../../hooks/useQuestions';
import { st, classes } from '../Widget.st.css';
import { DATA_HOOKS } from '../../../../constants/dataHooksElements';

export interface Props {
  questions: QuestionEntry[];
}

const QuestionsWrapper = ({ questions }: Props) => {
  const { questionsList, bottomRef } = useQuestions(questions);

  return (
    <>
      {questionsList.length > 0 ? (
        <QuestionsResultsFound questionsList={questionsList} />
      ) : (
        <NoQuestionsResult />
      )}
      <div
        data-hook={DATA_HOOKS.QUESTIONS_WRAPPER}
        ref={bottomRef}
        className={st(classes.bottomBody)}
      />
    </>
  );
};

export default QuestionsWrapper;
