import React, { useContext } from 'react';
import { Text, useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from '../../Widget.st.css';
import SelectedLayout from '../SelectedLayout/SelectedLayout';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { AppContext } from '../../SettingsProvider/SettingsProvider';
import { DATA_HOOKS } from '../../../../../constants/dataHooksElements';

export interface Props {
  questionsList: QuestionEntry[];
}

const QuestionsResultsFound = ({ questionsList }: Props) => {
  const { searchTerm } = useContext(AppContext);
  const { mobile } = useTPAComponentsContext();
  const { t } = useTranslation();
  return (
    <>
      <div
        className={st(classes.searchMessage, {
          resultFound: !!searchTerm,
          mobile,
        })}
        data-hook={DATA_HOOKS.WIDGET_QUESTIONS_RESULTS_FOUND}
      >
        {searchTerm && (
          <div className={st(classes.searchResultText)}>
            <Text data-hook={DATA_HOOKS.TEXT_SEARCH_RESULTS_FOUND}>
              {t('widget.search.tab.results.found', { searchTerm })}
            </Text>
          </div>
        )}
      </div>
      <SelectedLayout filteredQuestions={questionsList} />
    </>
  );
};

export default QuestionsResultsFound;
