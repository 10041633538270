import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import RCV from '../../../RCV';
import { st, classes } from '../../styles.st.css';
import { DraftJSObject } from '../../../../../../types/RichContent';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { AccordionCSS } from '../../Interfaces';
import settingsParams from '../../../../settingsParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import SocialWrapper from '../../../SocialWrapper/SocialWrapper';
import { DATA_HOOKS } from '../../../../../../constants/dataHooksElements';

const ListOneColumn = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const settings: ISettingsContextValue = useSettings();
  const { isMobile } = useEnvironment();
  const { gap } = settings.get(settingsParams.spacing);
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const customStyle = {
    '--item-gap': `${gap}px`,
  } as AccordionCSS;

  return (
    <div
      style={customStyle}
      className={st(classes.listLayout)}
      data-hook={DATA_HOOKS.WIDGET_LIST_ONE_COLUMN(
        filteredQuestions[0]?.id || '',
      )}
    >
      {filteredQuestions.map((item: QuestionEntry, index) => {
        const { id, question, draftjs } = item;
        const parsedDraftjs: DraftJSObject = JSON.parse(draftjs!);
        return (
          <div
            className={st(classes.listWrapperBorder, { itemAppearance })}
            key={id!}
            id={id!}
          >
            <div className={st(classes.listWrapperAccordion)}>
              <Text
                key={`question-${id}`}
                className={st(classes.question)}
                data-hook={DATA_HOOKS.LIST_ITEM_QUESTION(index)}
              >
                {question!}
              </Text>
              <Text
                key={`answer-${id}`}
                className={st(classes.answer)}
                data-hook={DATA_HOOKS.LIST_ITEM_ANSWER(index)}
              >
                {parsedDraftjs && <RCV convertAnswerToJSON={parsedDraftjs} />}
              </Text>
            </div>
            <SocialWrapper questionId={id!} />
          </div>
        );
      })}
    </div>
  );
};

export default ListOneColumn;
