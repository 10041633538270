import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';
import DefaultDesign from '../../design';

const defaultDesign = DefaultDesign;
export type IFaqOoiStylesParams = {
  headerTextColor: StyleParamType.Color;
  headerTextFont: StyleParamType.Font;
  selectedCategoryColor: StyleParamType.Color;
  categoryOtherTextColor: StyleParamType.Color;
  categoryOtherTextFont: StyleParamType.Font;
  widgetColor: StyleParamType.Color;
  questionTextColor: StyleParamType.Color;
  questionTextFont: StyleParamType.Font;
  questionStripesTextColor: StyleParamType.Color;
  questionStripesTextFont: StyleParamType.Font;
  questionNumberTextColor: StyleParamType.Color;
  questionNumberTextFont: StyleParamType.Font;
  questionNumberHoverColor: StyleParamType.Color;
  questionNumberBgColor: StyleParamType.Color;
  answerTextColor: StyleParamType.Color;
  answerTextFont: StyleParamType.Font;
  answersLinksColor: StyleParamType.Color;
  dividerCollapseColor: StyleParamType.Color;
  isCategoryChanged: StyleParamType.Boolean;
  numberQuestionsLayoutBorderColor: StyleParamType.Color;
  numberQuestionsLayoutBorderWidth: StyleParamType.Number;
  numberAnswersLayoutBorderColor: StyleParamType.Color;
  numberAnswersLayoutBorderWidth: StyleParamType.Number;
  numberAnswersLayoutBgColor: StyleParamType.Color;
};

export default createStylesParams<IFaqOoiStylesParams>({
  headerTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.headerText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.headerText.color.value),
  },
  headerTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.headerText.font.name,
    inheritDesktop: true,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam(defaultDesign.headerText.font.value, {
        size: isMobile
          ? defaultDesign.headerText.font.startWithMobileSize
          : defaultDesign.headerText.font.startWithSize,
        htmlTag: defaultDesign.headerText.font.htmlTag,
      })({ textPresets }),
  },
  selectedCategoryColor: {
    type: StyleParamType.Color,
    key: defaultDesign.selectedCategory.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.selectedCategory.color.value,
      defaultDesign.selectedCategory.color.opacity || 1,
    ),
  },
  categoryOtherTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.categoryOtherText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.categoryOtherText.color.value),
  },
  categoryOtherTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.categoryOtherText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.categoryOtherText.font.value, {
      size: defaultDesign.categoryOtherText.font.startWithSize,
    }),
  },

  widgetColor: {
    type: StyleParamType.Color,
    key: defaultDesign.widget.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.widget.color.value,
      defaultDesign.widget.color.opacity || 1,
    ),
  },
  questionTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.headerText.color.value),
  },
  questionTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionText.font.name,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam(defaultDesign.headerText.font.value, {
        lineHeight: defaultDesign.questionText.font.lineHeight,
        size: isMobile
          ? defaultDesign.questionText.font.startWithMobileSize
          : defaultDesign.questionText.font.startWithSize,
        htmlTag: defaultDesign.questionText.font.htmlTag,
      })({ textPresets }),
  },
  questionStripesTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionStripesText.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionStripesText.color.value,
    ),
  },
  questionStripesTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionStripesText.font.name,
    getDefaultValue: wixFontParam(
      defaultDesign.questionStripesText.font.value,
      {
        size: defaultDesign.questionStripesText.font.startWithSize,
        htmlTag: defaultDesign.questionStripesText.font.htmlTag,
      },
    ),
  },
  questionNumberTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionNumberText.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionNumberText.color.value,
    ),
  },
  questionNumberTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionNumberText.font.name,
    getDefaultValue: ({ isMobile, textPresets }) =>
      wixFontParam(defaultDesign.questionNumberText.font.value, {
        size: isMobile
          ? defaultDesign.questionNumberText.font.startWithMobileSize
          : defaultDesign.questionNumberText.font.startWithSize,
      })({ textPresets }),
  },

  questionNumberHoverColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionNumberHover.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionNumberHover.color.value,
      defaultDesign.questionNumberHover.color.opacity || 1,
    ),
  },
  questionNumberBgColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionNumberBgColor.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionNumberBgColor.color.value,
      defaultDesign.questionNumberBgColor.color.opacity || 1,
    ),
  },
  numberQuestionsLayoutBorderColor: {
    type: StyleParamType.Color,
    key: defaultDesign.numberQuestionsLayoutBorder.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.numberQuestionsLayoutBorder.color.value,
      defaultDesign.numberQuestionsLayoutBorder.color.opacity || 0.75,
    ),
  },
  numberQuestionsLayoutBorderWidth: {
    type: StyleParamType.Number,
    key: defaultDesign.numberQuestionsLayoutBorder.border.name,
    getDefaultValue: () =>
      defaultDesign.numberQuestionsLayoutBorder.border.value,
  },
  numberAnswersLayoutBgColor: {
    type: StyleParamType.Color,
    key: defaultDesign.numberAnswersLayoutBgColor.name,
    getDefaultValue: wixColorParam(
      defaultDesign.numberAnswersLayoutBgColor.value,
      defaultDesign.numberAnswersLayoutBgColor.opacity || 1,
    ),
  },
  numberAnswersLayoutBorderColor: {
    type: StyleParamType.Color,
    key: defaultDesign.numberAnswersLayoutBorder.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.numberAnswersLayoutBorder.color.value,
      defaultDesign.numberAnswersLayoutBorder.color.opacity || 0.75,
    ),
  },
  numberAnswersLayoutBorderWidth: {
    type: StyleParamType.Number,
    key: defaultDesign.numberAnswersLayoutBorder.border.name,
    getDefaultValue: () => defaultDesign.numberAnswersLayoutBorder.border.value,
  },
  answerTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.answerText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.answerText.color.value),
  },
  answerTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.answerText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.answerText.font.value, {
      size: defaultDesign.answerText.font.startWithSize,
      lineHeight: defaultDesign.answerText.font.lineHeight,
    }),
  },
  answersLinksColor: {
    type: StyleParamType.Color,
    key: defaultDesign.answersLinks.color.name,
    getDefaultValue: wixColorParam(defaultDesign.answersLinks.color.value),
  },
  dividerCollapseColor: {
    type: StyleParamType.Color,
    key: defaultDesign.dividerCollapse.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.dividerCollapse.color.value,
      defaultDesign.dividerCollapse.color.opacity || 1,
    ),
  },
  isCategoryChanged: {
    type: StyleParamType.Boolean,
    key: 'isCategoryChanged',
    getDefaultValue: () => false,
  },
});
