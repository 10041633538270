import { useState, useEffect, useMemo, useContext } from 'react';
import { FAQService } from '../utils/FAQService';
import { AppContext } from '../components/FaqOoi/Widget/SettingsProvider/SettingsProvider';
import settingsParams from '../components/FaqOoi/settingsParams';
import constants from '../constants';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useCategories = (validLists: Category[]) => {
  const settings: ISettingsContextValue = useSettings();
  const { isViewer, multilingual } = useEnvironment();
  const { instance, instanceId, setSelectedCategory, selectedCategory } =
    useContext(AppContext);

  const api = useMemo(
    () => new FAQService(instance ?? '', instanceId, multilingual),
    [instance, instanceId, multilingual],
  );

  const displayContent = settings.get(settingsParams.displayContent);
  const categoriesPosition = settings.get(settingsParams.categoriesPosition);
  const manageCategories = settings.get(settingsParams.manageCategories);
  const showAll = manageCategories?.showAll;
  const categoriesJSON = JSON.stringify(manageCategories?.categories);
  const categories = useMemo(
    () => JSON.parse(categoriesJSON),
    [categoriesJSON],
  );
  const splitIntoCategories =
    displayContent === constants.DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES;
  const oneListOfQuestions =
    displayContent === constants.DISPLAY_CONTENT.ONE_LIST_OF_QUESTIONS;
  const sideCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.SIDE;
  const topCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.TOP;

  // State for categories list
  const [listCategories, setListCategories] = useState<Category[]>(
    validLists || [],
  );
  const filteredCategoriesIds = listCategories.map((category) => category.id);

  // Manage active tab
  const [activeTab, setActiveTab] = useState(
    typeof selectedCategory === 'string'
      ? filteredCategoriesIds.indexOf(selectedCategory)
      : 0,
  );

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesResponse = await api.getListCategories();
      const { categories: categoriesList } = categoriesResponse;

      let firstId: string | undefined | null;
      const localValidLists = categoriesList?.length
        ? categoriesList?.filter((category) => {
            const categoryId = category?.id;
            if (!categoryId) {
              return false;
            }
            if (showAll || categories?.includes(categoryId)) {
              if (firstId === undefined) {
                firstId = categoryId;
              }
              return true;
            }
            return false;
          })
        : [];

      setListCategories(localValidLists ?? []);

      if (oneListOfQuestions) {
        const validCategoryIds = localValidLists
          ?.filter((category: Category) => category?.id)
          .map((category: Category) => category?.id);

        setSelectedCategory?.(validCategoryIds);
      } else if (firstId) {
        setSelectedCategory?.(firstId);
      }
    };

    if (!isViewer) {
      fetchCategories();
    }
  }, [
    api,
    categories,
    isViewer,
    oneListOfQuestions,
    setSelectedCategory,
    showAll,
  ]);

  // Handle tab selection updates
  useEffect(() => {
    if (setSelectedCategory && !oneListOfQuestions) {
      const categoryId = listCategories[activeTab]?.id;
      if (categoryId) {
        setSelectedCategory?.(categoryId);
      }
    }
  }, [activeTab, listCategories, oneListOfQuestions, setSelectedCategory]);

  // Filter categories to render
  const categoriesToRender = useMemo(() => {
    if (showAll) {
      return listCategories;
    }
    return categories
      ? listCategories?.filter((c) => categories.includes(c.id!))
      : [];
  }, [showAll, categories, listCategories]);

  // Adjust active tab if necessary
  const adjustedActiveTab = useMemo(() => {
    return categoriesToRender.length > 0 &&
      activeTab >= categoriesToRender.length
      ? 0
      : activeTab;
  }, [activeTab, categoriesToRender]);

  return {
    categoriesToRender,
    activeTab: adjustedActiveTab,
    setActiveTab,
    splitIntoCategories,
    topCategoriesPosition,
    sideCategoriesPosition,
  };
};
