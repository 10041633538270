import React from 'react';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import {
  AccordionTheme,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams, { LayoutType } from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { DATA_HOOKS } from '../../../../../constants/dataHooksElements';

export interface VerticalCategoriesProps {
  categoriesToRender: Category[];
  activeTab: number;
  sideCategoriesPosition: boolean;
  setActiveTab: (index: number) => void;
}

const VerticalCategories = ({
  categoriesToRender,
  activeTab,
  sideCategoriesPosition,
  setActiveTab,
}: VerticalCategoriesProps) => {
  const settings: ISettingsContextValue = useSettings();
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const layout: LayoutType = settings.get(settingsParams.layout);
  const numberLayout = layout === LayoutType.Stripes;
  const theme = numberLayout ? AccordionTheme.Box : itemAppearance;

  return (
    <div
      className={st(classes.Tabs, {
        isSideCategoriesPosition: sideCategoriesPosition,
        theme,
      })}
      data-hook={DATA_HOOKS.WIDGET_CATEGORIES_VERTICAL}
    >
      {categoriesToRender.map((category, index) => {
        return (
          <TextButton
            key={`category-button-${index}`}
            priority={
              activeTab === index
                ? TextButtonPriority.link
                : TextButtonPriority.secondary
            }
            className={st(classes.underlineTextColor, {
              isLink: activeTab === index,
            })}
            onClick={setActiveTab.bind(null, index)}
            data-hook={DATA_HOOKS.WIDGET_CATEGORIES_VERTICAL_ITEM(index)}
          >
            {category.title}
          </TextButton>
        );
      })}
    </div>
  );
};

export default VerticalCategories;
