import React, { useContext } from 'react';
import { useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import { faqButtonClickSrc63Evid836 } from '@wix/bi-logger-labs-data/v2';
import { useBi } from '@wix/yoshi-flow-editor';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import VerticalTabs from './VerticalCategories';
import HorizontalCategories from './HorizontalCategories';
import { useCategories } from '../../../../hooks/useCategories';
import { AppContext } from '../SettingsProvider/SettingsProvider';

export interface TabsWrapperProps {
  validLists: Category[];
}

const TabsWrapper = ({ validLists }: TabsWrapperProps) => {
  const logger = useBi();
  const { setFirstQuestionId, searchTerm } = useContext(AppContext);
  const { rtl } = useTPAComponentsContext();

  const {
    categoriesToRender,
    activeTab,
    setActiveTab,
    splitIntoCategories,
    topCategoriesPosition,
    sideCategoriesPosition,
  } = useCategories(validLists);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    setFirstQuestionId?.(undefined);
    const category = categoriesToRender[index];
    logger.report(
      faqButtonClickSrc63Evid836({
        button_name: category?.id || category?.title || `${index}`,
        action: 'tab',
      }),
    );
  };

  const isDisplayCategories =
    !searchTerm && splitIntoCategories && categoriesToRender.length > 0;

  return (
    <>
      {isDisplayCategories && topCategoriesPosition && (
        <HorizontalCategories
          categoriesToRender={categoriesToRender}
          adjustedActiveTab={activeTab}
          setActiveTab={handleTabClick}
          splitIntoCategories={splitIntoCategories}
          rtl={rtl || false}
        />
      )}
      {isDisplayCategories && sideCategoriesPosition && (
        <VerticalTabs
          sideCategoriesPosition={sideCategoriesPosition}
          categoriesToRender={categoriesToRender}
          activeTab={activeTab}
          setActiveTab={handleTabClick}
        />
      )}
    </>
  );
};

export default TabsWrapper;
