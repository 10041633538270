import React from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import NumberOneColumn from './NumberOneColumn';

const NumberTwoColumns = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const leftQuestions = filteredQuestions.filter(
    (item, index) => index % 2 === 0,
  );
  const rightQuestions = filteredQuestions.filter(
    (item, index) => index % 2 === 1,
  );

  const indexesLeftQuestions = leftQuestions.map(
    (item, index) => index * 2 + 1,
  );
  const indexesRightQuestions = rightQuestions.map(
    (item, index) => index * 2 + 2,
  );

  return (
    <>
      <div>
        <NumberOneColumn
          filteredQuestions={leftQuestions}
          indexes={indexesLeftQuestions}
        />
      </div>
      <div>
        <NumberOneColumn
          filteredQuestions={rightQuestions}
          indexes={indexesRightQuestions}
        />
      </div>
    </>
  );
};

export default NumberTwoColumns;
