import { createContext } from 'react';

// Define the interface for the context's value
export interface AppContextInterface {
  instance: string;
  instanceId: string;
  baseURL: string;
  selectedCategory?: (string | null | undefined)[] | string | null | undefined;
  setSelectedCategory?: (
    category: (string | null | undefined)[] | undefined | string | null,
  ) => void;
  firstQuestionId?: string;
  setFirstQuestionId?: (id: string | undefined) => void;
  questionId: string;
  appAPI?: any;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}
export const AppContextDefaultValues: AppContextInterface = {
  instance: '',
  instanceId: '',
  baseURL: '',
  setSelectedCategory: () => {},
  firstQuestionId: '',
  setFirstQuestionId: () => {},
  questionId: '',
  appAPI: {},
  searchTerm: '',
  setSearchTerm: () => {},
};
// Create the context and provide a default value that matches the interface
export const AppContext = createContext<AppContextInterface>(
  AppContextDefaultValues,
);
