import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { debounce } from 'lodash';
import { AppContext } from '../components/FaqOoi/Widget/SettingsProvider/SettingsProvider';
import { FAQService } from '../utils/FAQService';
import { PaginationState } from './usePaginationQuestions';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

export interface PaginationSearchQuestionsProps {
  setQuestionsList: React.Dispatch<React.SetStateAction<QuestionEntry[]>>;
  bottomRef: React.RefObject<HTMLDivElement>;
  api: FAQService;
}

export const usePaginationSearchQuestions = ({
  setQuestionsList,
  bottomRef,
  api,
}: PaginationSearchQuestionsProps) => {
  const { selectedCategory, searchTerm } = useContext(AppContext);
  const [searchPaginationState, setSearchPaginationState] =
    useState<PaginationState>({
      cursor: null,
      loading: false,
      hasMore: false,
    });

  const firstLoad = useRef(true);

  // Search - fetch questions from API to get search results
  const fetchQuestionsSearch = useCallback(
    async (term?: string) => {
      setSearchPaginationState((prev) => ({ ...prev, loading: true }));
      try {
        const response = term
          ? await api.getSearchTerm({ term })
          : await api.getListQuestions({ categoryId: selectedCategory });

        setQuestionsList(response.questionEntries || []);
        setSearchPaginationState({
          cursor: response.pagingMetadata?.cursors?.next || null,
          loading: false,
          hasMore: response.pagingMetadata?.hasNext || false,
        });
      } catch (error) {
        console.error('Error fetching questions:', error);
        setSearchPaginationState((prev) => ({ ...prev, loading: false }));
      }
    },
    [api, selectedCategory, setSearchPaginationState, setQuestionsList],
  );

  // Debounced search
  const debouncedSearch = useMemo(
    () => debounce(fetchQuestionsSearch, 400),
    [fetchQuestionsSearch],
  );

  useEffect(() => {
    if (!firstLoad.current) {
      debouncedSearch(searchTerm);
    }
    firstLoad.current = false;
  }, [searchTerm, debouncedSearch]);

  // Load More Questions (Infinite Scrolling)
  const loadMoreQuestions = useCallback(async () => {
    if (
      !searchPaginationState.cursor ||
      searchPaginationState.loading ||
      !searchPaginationState.hasMore
    ) {
      return;
    }

    setSearchPaginationState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await api.getSearchTerm({
        term: searchTerm,
        cursor: searchPaginationState.cursor,
      });

      setQuestionsList((prev) => [
        ...prev,
        ...(response.questionEntries || []),
      ]);
      setSearchPaginationState({
        cursor: response.pagingMetadata?.cursors?.next || null,
        hasMore: response.pagingMetadata?.hasNext || false,
        loading: false,
      });
    } catch (error) {
      console.error('Error loading more questions:', error);
      setSearchPaginationState((prev) => ({ ...prev, loading: false }));
    }
  }, [
    api,
    searchPaginationState,
    searchTerm,
    setSearchPaginationState,
    setQuestionsList,
  ]);

  // Intersection Observer for Infinite Scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          searchPaginationState.hasMore &&
          !searchPaginationState.loading
        ) {
          loadMoreQuestions();
        }
      },
      { rootMargin: `${window.innerHeight * 0.6}px` },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    return () => observer.disconnect();
  }, [
    searchPaginationState.hasMore,
    searchPaginationState.loading,
    loadMoreQuestions,
    bottomRef,
  ]);

  return { searchPaginationState };
};
