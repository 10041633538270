import React from 'react';
import { st, classes } from '../../Widget.st.css';
import AccordionsTwoColumns from './AccordionsTwoColumns';
import AccordionsOneColumn from './AccordionOneColumn';
import { Structure } from '../../../../../enums';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../../settingsParams';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { AccordionCSS } from '../Interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DATA_HOOKS } from '../../../../../constants/dataHooksElements';

const AccordionWrapper = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const { isMobile } = useEnvironment();
  const settings: ISettingsContextValue = useSettings();
  const structureColumn: Structure = settings.get(settingsParams.structure);
  const twoColumnStructure: boolean =
    structureColumn === Structure.TWO_COLUMNS && !isMobile;
  const { gap, betweencols } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-gap': `${gap}px`,
    '--column-gap': `${betweencols}px`,
  } as AccordionCSS;

  return (
    <div
      style={customStyle}
      className={st(classes.Body, { isTwoColumn: twoColumnStructure })}
      data-hook={DATA_HOOKS.WIDGET_ACCORDION_WRAPPER}
    >
      {twoColumnStructure ? (
        <AccordionsTwoColumns filteredQuestions={filteredQuestions} />
      ) : (
        <AccordionsOneColumn filteredQuestions={filteredQuestions} />
      )}
    </div>
  );
};

export default AccordionWrapper;
