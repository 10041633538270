import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  useEnvironment,
  WidgetProps,
  IHostProps,
  useBi,
} from '@wix/yoshi-flow-editor';
import { faqAppLoads, faqQuestionExpanded } from '@wix/bi-logger-labs-data/v2';
import { pauseHeightChanges } from '@wix/yoshi-flow-editor/wix-height-updater';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import TabsWrapper from './Tabs/TabsWrapper';
import { AppContext } from './SettingsProvider/SettingsProvider';
import settingsParams from '../settingsParams';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from './Widget.st.css';
import QuestionsWrapper from './QuestionsWrapper/QuestionsWrapper';
import SearchWrapper from './SearchWrapper/SearchWrapper';
import { CategoriesPosition } from '../../../enums';
import constants from '../../../constants';
import { AccordionCSS } from './QuestionsWrapper/Interfaces';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

export interface ControllerProps {
  instance: string;
  instanceId: string;
  baseURL: string;
  questionId: string;
  api: any;
  initialData: any;
  fromSearch: boolean;
  host?: IHostProps;
  fitToContentHeight: boolean;
}
export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const {
    instance,
    instanceId,
    baseURL,
    api: appAPI,
    initialData,
    questionId,
    fromSearch,
    host,
  } = props;
  const logger = useBi();
  const settings: ISettingsContextValue = useSettings();
  const { isMobile, isEditor, multilingual } = useEnvironment();
  const textAlignment = settings.get(settingsParams.textAlignment);
  const { DISPLAY_CATEGORIES, DISPLAY_CONTENT, RTL_LANGUAGE } = constants;
  let isRTL = textAlignment === 'right';

  if (multilingual?.isEnabled) {
    const languageCode = multilingual.currentLanguage?.split('-')[0] || '';
    isRTL = RTL_LANGUAGE.includes(languageCode);
  }

  const { vertical, horizontal } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-top-bottom-padding': `${vertical}px`,
    '--item-left-right-padding': `${horizontal}px`,
  } as AccordionCSS;
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const sideCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.SIDE;
  const topCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.TOP;
  const [searchTerm, setSearchTerm] = React.useState('');
  const showCategories =
    settings.get(settingsParams.displayContent) ===
      DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES || searchTerm !== '';
  const [selectedCategory, setSelectedCategory] = useState<
    (string | null | undefined)[] | undefined | string | null
  >(initialData?.selectedCategory);
  const handleSearchChange = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);
  const { show: showSearch } = settings.get(settingsParams.searchBar);
  const showFirstAnswer: boolean = settings.get(settingsParams.openFirstAnswer);
  const [firstQuestionId, setFirstQuestionId] = useState<string | undefined>(
    questionId,
  );
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  useEffect(() => {
    pauseHeightChanges();
  }, [host]);

  useEffect(() => {
    const { validLists, questionEntries } = initialData;
    const num_of_categories_shown = validLists?.length ?? 0;
    const { categories } = settings.get(settingsParams.manageCategories);
    const num_of_questions = questionEntries?.length ?? 0;
    const num_of_categories = categories?.length ?? 0;
    const layout_type = settings.get(settingsParams.layout);
    const columns = settings.get(settingsParams.structure);
    const category_placement = settings.get(settingsParams.categoriesPosition);
    logger.report(
      faqAppLoads({
        num_of_categories_shown,
        num_of_categories,
        num_of_questions,
        layout_type,
        columns,
        category_placement,
      }),
    );
  }, [initialData, logger, settings]);

  useEffect(() => {
    if (isEditor) {
      if (!showFirstAnswer) {
        setFirstQuestionId(undefined);
      } else {
        setFirstQuestionId(questionId);
      }
    } else {
      if (questionId && fromSearch) {
        const { questionEntries } = initialData;
        const questionData = questionEntries?.find(
          (q: QuestionEntry) => q.id === questionId,
        );

        if (questionData) {
          const { question, categoryId, sortOrder } = questionData;
          logger.report(
            faqQuestionExpanded({
              type: 'deep link',
              question_id: questionId,
              question_name: question || '',
              category_id: categoryId || '',
              question_order: sortOrder || 0,
              url: baseURL || '',
            }),
          );
        }

        const element = document.getElementById(`${questionId}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          element.focus();
        }
      }
    }
  }, [fromSearch, isEditor, questionId, showFirstAnswer, initialData, logger]);

  return (
    <TPAComponentsProvider value={{ rtl: isRTL, mobile: isMobile }}>
      <AppContext.Provider
        value={{
          instance,
          instanceId,
          baseURL,
          selectedCategory,
          setSelectedCategory,
          firstQuestionId,
          setFirstQuestionId,
          questionId,
          appAPI,
          searchTerm,
          setSearchTerm,
        }}
      >
        <div
          data-hook="faq-root"
          dir={isRTL ? 'rtl' : 'ltr'}
          style={customStyle}
          className={st(classes.container, {
            mobile: isMobile,
            isSideCategoriesPosition:
              searchTerm !== '' ? false : sideCategoriesPosition,
            isTopCategoriesPosition: topCategoriesPosition || searchTerm !== '',
            hideSearch: !showSearch,
            hideCategories: !showCategories,
            theme: itemAppearance,
          })}
        >
          <SearchWrapper onSearchChange={handleSearchChange} />
          <HeaderWrapper />
          <TabsWrapper validLists={initialData.validLists} />
          <QuestionsWrapper questions={initialData.questionEntries} />
        </div>
      </AppContext.Provider>
    </TPAComponentsProvider>
  );
};

export default Widget;
