export const DATA_HOOKS = {
  GLOBAL_HOOK: 'tpa-components-provider',
  WIDGET_SEARCH_ELEMENT: 'faq-search',
  WIDGET_NO_SEARCH_RESULTS: 'no-search-results',
  QUESTIONS_WRAPPER: 'questions-wrapper',
  NO_QUESTION_RESULTS: 'no-question-results',
  WIDGET_QUESTIONS_RESULTS_FOUND: 'questions-results-found',
  TEXT_SEARCH_RESULTS_FOUND: 'text-search-results-found',
  WIDGET_HEADER_TITLE: 'header-title',
  WIDGET_HEADER_TITLE_TEXT: 'title',
  WIDGET_CATEGORIES_WRAPPER: 'tabs-wrapper',
  WIDGET_CATEGORIES_VERTICAL: 'faq-tabs-vertical',
  WIDGET_ACCORDION_WRAPPER: 'widget-accordion-wrapper',
  WIDGET_CATEGORIES_VERTICAL_ITEM: (index: number) =>
    `category-button-${index}`,
  WIDGET_CATEGORIES_HORIZONTAL: 'faq-tabs',
  WIDGET_ACCORDION_ONE_COLUMN: (key: string) => `accordion-one-column-${key}`,
  WIDGET_LIST_ONE_COLUMN: (key: string) => `list-one-column${key}`,
  LIST_ITEM_QUESTION: (index: number) => `list-item-question-${index}`,
  LIST_ITEM_ANSWER: (index: number) => `list-item-answer-${index}`,
};
