import React, { useContext } from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { Accordion, AccordionTheme } from 'wix-ui-tpa/cssVars';
import { st, classes } from './accordion.st.css';
import { st as wst, classes as wclasses } from '../../../Widget.st.css';
import SocialWrapper from '../../../SocialWrapper/SocialWrapper';
import RCV from '../../../RCV';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams, {
  ItemAppearance,
  OpenQuestionIcon,
} from '../../../../settingsParams';
import { AppContext } from '../../../SettingsProvider/SettingsProvider';
import { openIconBySettings } from '../../../../../../utils/openIconBySettings';
import { useBi } from '@wix/yoshi-flow-editor';
import { faqQuestionExpanded } from '@wix/bi-logger-labs-data/v2';
import { DraftJSObject } from '../../../../../../types/RichContent';
import { DATA_HOOKS } from '../../../../../../constants/dataHooksElements';

const AccordionOneColumn = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const logger = useBi();
  const { firstQuestionId, baseURL } = useContext(AppContext);
  const settings: ISettingsContextValue = useSettings();

  // Extract settings for item appearance and animation
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const noAnimation = !settings.get(settingsParams.enableScrollAnimation);
  const openOneAnswer = settings.get(settingsParams.openOneAnswer);
  const layout_type = settings.get(settingsParams.layout);

  // Determine accordion theme based on appearance setting
  const accordionTheme: AccordionTheme =
    itemAppearance === ItemAppearance.BOX
      ? AccordionTheme.Box
      : AccordionTheme.Line;

  // Determine the icon for expanded questions
  const openQuestionIcon =
    settings?.get(settingsParams.openQuestionIcon) || OpenQuestionIcon.ARROW;
  const icons = openIconBySettings(openQuestionIcon);

  // Event handler for tracking question clicks
  const handleAccordionItemClick = (itemId: string) => {
    const questionData = filteredQuestions.find(
      (q: QuestionEntry) => q.id === itemId,
    );

    if (questionData) {
      const { question, categoryId, sortOrder } = questionData;
      logger.report(
        faqQuestionExpanded({
          type: layout_type,
          question_id: itemId,
          question_name: question || '',
          category_id: categoryId || '',
          question_order: sortOrder || 0,
          url: baseURL || '',
        }),
      );
    }
  };

  // Build the className for the accordion
  const stClassAccordion = st(classes.flexAccordion, {
    noAnimation,
    itemAppearance,
  });

  // Early return if there are no questions to display
  if (!filteredQuestions.length) {
    return null;
  }

  return (
    <Accordion
      key={`accordion-${firstQuestionId}-${filteredQuestions.at(-1)?.id}`}
      className={st(classes.root, stClassAccordion)}
      icons={icons}
      multiple={!openOneAnswer}
      initiallyExpanded={`${firstQuestionId}`}
      theme={accordionTheme}
      onItemClick={(item, isExpanded) =>
        isExpanded && handleAccordionItemClick(item)
      }
      data-hook={DATA_HOOKS.WIDGET_ACCORDION_ONE_COLUMN(
        filteredQuestions[0]?.id || '',
      )}
    >
      {filteredQuestions.map(({ id, question, draftjs }) => {
        const parsedDraftjs: DraftJSObject = draftjs
          ? JSON.parse(draftjs)
          : null;

        return (
          <Accordion.Item
            key={id!}
            id={id!}
            title={question!}
            suffix={<div className={wst(wclasses.hidesuffix)}></div>}
          >
            {parsedDraftjs && <RCV convertAnswerToJSON={parsedDraftjs} />}
            <SocialWrapper questionId={id!} />
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default AccordionOneColumn;
